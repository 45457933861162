import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import toastr from "src/components/Common/toastr";
import {
  INVALID_WEBHOOK_URL_MESSAGE,
  INVALID_WEBHOOK_URL_MESSAGE_HIPAA,
} from "src/constants";
import { validateWebhookURL } from "src/modules/developer/helpers";
import { AlertTriangleIcon, ClockIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Loading, Switch, Text } from "@100mslive/react-ui";
import CustomTable from "./CustomTable.jsx";
import SwitchWithTitle from "./SwitchWithTitle.jsx";
import Credential from "../../components/Common/Credential";
import { API_CALL_STATE } from "../../constants";
import { handleNonAdminWorkspaceRoute } from "../../helpers";
import jwt_verify from "../../helpers/token_expiry";
import {
  fetchWorkspaceDetails,
  fetchWorkspaces,
  generateJWTToken,
  updateWebhookDetails,
  updateWorkspaceDetails,
  verifyWebhookURLData,
} from "../../store/actions";
import { formattedDate, getCurrentWorkspaceID, isAdmin } from "../../utils";
import "toastr/build/toastr.min.css";

// eslint-disable-next-line complexity
function Developer() {
  const dispatch = useDispatch();
  const {
    current,
    workspaces,
    error: verifyWebhookURLError,
  } = useSelector(state => state.workspace);
  const fetchAllWorkspaces = useSelector(
    state => state.workspace?.status?.fetchAllWorkspaces
  );
  if (
    !workspaces[getCurrentWorkspaceID()]?.is_admin &&
    fetchAllWorkspaces === API_CALL_STATE.DONE
  ) {
    handleNonAdminWorkspaceRoute();
  }
  const {
    generateManagementToken,
    fetchWorkspaceInfo,
    updateWorkspaceInfo,
    verifyWebhookURL,
  } = useSelector(state => state.workspace.status);
  const [enableWebhook, setEnableWebhook] = useState(
    current?.Webhook?.Active || false
  );
  const [tableData, setTableData] = useState([
    { id: 0, headerKey: "", headerValue: "" },
  ]);
  const [webhookURL, setWebhookURL] = useState(current?.Webhook?.URL || "");
  const [isInvalidHippaWebhookURL, setisInvalidHippaWebhookURL] =
    useState(false);
  const [tokenExpiryDays, setTokenExpiryDays] = useState(7);
  const [invalidWebhookConfig, setInvalidWebhookConfig] = useState(false);
  const getIsSecret = label =>
    ["App Secret", "App Access Key", "Username", "Password"].includes(label);
  const maximumManagementDaysValidity = 14;
  const minimumManagementDaysValidity = 1;

  const getTableData = useCallback(tableData => {
    let headers = {};
    tableData.forEach(header => {
      const key = header.headerKey,
        value = header.headerValue;
      if (key && value) {
        headers[key] = value;
      }
    });
    return headers;
  }, []);

  const changeTableData = useCallback(
    newData => {
      const data = cloneDeep(newData);
      setTableData(data);
    },
    [setTableData]
  );

  useEffect(() => {
    jwt_verify();
    toastr.options.closeButton = true;
  }, []);

  useEffect(() => {
    if (isEmpty(current)) {
      dispatch(fetchWorkspaceDetails());
    }
  }, [current, dispatch]);

  useEffect(() => {
    const isInValid =
      workspaces[getCurrentWorkspaceID()]?.hipaa &&
      webhookURL.startsWith("http") &&
      !webhookURL.startsWith("https");
    setisInvalidHippaWebhookURL(isInValid);
  }, [webhookURL, workspaces]);

  useEffect(() => {
    if (
      fetchWorkspaceInfo === API_CALL_STATE.DONE &&
      !isEmpty(current?.Webhook)
    ) {
      const webhook = current?.Webhook;
      if (webhook.URL) {
        setWebhookURL(webhook.URL);
      }
      if (!isEmpty(webhook.Headers)) {
        let tempTableData = [];
        Object.keys(current.Webhook.Headers).forEach(key => {
          tempTableData.push({
            id: Math.floor(1000 + Math.random() * 9000),
            headerKey: key,
            headerValue: current.Webhook.Headers[key],
          });
        });
        setTableData(tempTableData);
      }
      if (webhook.Active) {
        setEnableWebhook(webhook.Active);
      }
    }
  }, [current.Webhook, fetchWorkspaceInfo]);

  useEffect(() => {
    let error = false;
    if (!validateWebhookURL(webhookURL) || (enableWebhook && !webhookURL)) {
      error = INVALID_WEBHOOK_URL_MESSAGE;
    } else if (isInvalidHippaWebhookURL) {
      error = INVALID_WEBHOOK_URL_MESSAGE_HIPAA;
    } else if (
      (current?.Webhook?.URL || "") === webhookURL &&
      !!current?.Webhook?.Active === enableWebhook &&
      isEqual(getTableData(tableData), current?.Webhook?.Headers || {})
    ) {
      error = true;
    }
    setInvalidWebhookConfig(error);
  }, [
    enableWebhook,
    webhookURL,
    tableData,
    current?.Webhook,
    getTableData,
    isInvalidHippaWebhookURL,
  ]);

  const handleSave = useCallback(
    async e => {
      e.preventDefault();
      const webhook = {
        URL: webhookURL,
        Headers: getTableData(tableData),
        Active: enableWebhook,
      };
      dispatch(
        updateWebhookDetails({
          Webhook: { ...webhook },
        })
      );
    },
    [dispatch, enableWebhook, getTableData, tableData, webhookURL]
  );

  const CredentialsCSS = {
    css: { flexDirection: "column", maxWidth: "400px" },
    inputCss: {
      border: "1px solid $borderLight",
      backgroundColor: "$surfaceDefault",
      borderRadius: "$0",
    },
    labelCss: {
      fontWeight: "$semiBold",
      color: "$textMedEmp",
      fontSize: "$xs",
    },
  };

  return isEmpty(current) ? (
    <>
      <div className="page-content">
        <Container fluid>
          <Flex css={{ w: "100%", h: "100vh" }} justify="center" align="center">
            <Loading />
          </Flex>
        </Container>
      </div>
    </>
  ) : (
    <>
      <div className="page-content">
        <Container fluid>
          <Text variant="h5" css={{ color: "$textHighEmp", m: "0", mb: "$4" }}>
            Developer
          </Text>
          <Text variant="sm" css={{ color: "$textMedEmp", m: "0" }}>
            Developer section houses essential access credentials, webhook
            configurations, and security settings for your 100ms account.
          </Text>
          <Text variant="h6" css={{ m: "0", mt: "$14", color: "$textHighEmp" }}>
            Access Credentials
          </Text>

          <Text
            variant="sm"
            css={{ color: "$textDisabled", mt: "$4", mb: "$12" }}
          >
            Authentication credentials for client and server side setup.
            <a
              className="text-primary-light hover:text-primary-default"
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_WEBSITE_URL}docs/concepts/v2/concepts/security-and-tokens`}
            >
              &nbsp;Learn more.
            </a>
          </Text>

          <Box css={{ mb: "$14" }}>
            <Flex css={{ gap: "$12", marginBottom: "$12" }}>
              <Credential
                readOnly
                {...CredentialsCSS}
                outlined
                label="App Access Key"
                value={current?.Auth?.ID}
                getIsSecret={getIsSecret}
              />
              <Credential
                {...CredentialsCSS}
                outlined
                readOnly
                label="App Secret"
                value={current?.Auth?.Secret}
                getIsSecret={getIsSecret}
              />
            </Flex>
            <Flex css={{ gap: "$12" }}>
              <Flex
                direction="column"
                css={{ gap: "$3", w: "100%", maxWidth: "400px" }}
              >
                <Credential
                  readOnly
                  outlined
                  {...CredentialsCSS}
                  label="Management Token"
                  value={current?.management_token}
                  getIsSecret={getIsSecret}
                  tooltipMessage="This token is not stored and a new token will be generated
                      upon page refresh. There is no way to retrieve or disable
                      it."
                  tooltipLink={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/security-and-tokens#management-token-for-rest-api`}
                />
                <Flex
                  align="center"
                  css={{
                    color: "$textMedEmp",
                    // mt: "$4",
                    gap: "$2",
                    justifyItems: "center",
                  }}
                >
                  <ClockIcon height={16} width={16} />
                  <Text variant="xs" css={{ color: "inherit" }}>
                    {current?.managementTokenExpiration
                      ? `Expires on ${formattedDate(
                          current?.managementTokenExpiration
                        )}`
                      : "Expires in 24 hours"}
                  </Text>
                </Flex>
              </Flex>
              <Flex direction="column" css={{ gap: "$3", w: "100%" }}>
                <Flex css={{ w: "100%", gap: "$8", alignItems: "flex-end" }}>
                  <Credential
                    readOnly={false}
                    outlined
                    {...CredentialsCSS}
                    css={{
                      width: "100px",
                      flexDirection: "column",
                    }}
                    label="Token Validity"
                    rightComp={<>Days</>}
                    onChangeHandler={value => {
                      const cleanedValue = value.replace(/\D/g, "");
                      const parsedValue = parseInt(cleanedValue, 10);
                      if (value > 0) {
                        setTokenExpiryDays(parsedValue);
                      } else {
                        setTokenExpiryDays("");
                      }
                    }}
                    value={tokenExpiryDays}
                    showClipboard={false}
                    tooltipMessage="This defines the expiry duration of the token. For security reasons, its maximum validity is 14 days."
                  />
                  <Button
                    disabled={
                      tokenExpiryDays > maximumManagementDaysValidity ||
                      tokenExpiryDays < minimumManagementDaysValidity
                    }
                    variant="standard"
                    css={{ r: "$0" }}
                    loading={
                      generateManagementToken === API_CALL_STATE.IN_PROGRESS
                    }
                    onClick={() => {
                      dispatch(generateJWTToken(tokenExpiryDays));
                    }}
                  >
                    {current?.management_token ? "Regenerate" : "Generate"}
                  </Button>
                </Flex>
                {tokenExpiryDays > maximumManagementDaysValidity && (
                  <Flex align="center" css={{ color: "$error", gap: "$3" }}>
                    <AlertTriangleIcon height={16} width={16} />
                    <Text
                      variant="xs"
                      css={{
                        c: "$error",
                        w: "fit-content",
                      }}
                    >
                      Token Validity exceeds the maximum permissible value of 14
                      days
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Box>
          <hr />

          <Text variant="h6" css={{ m: "0", mt: "$14", color: "$textHighEmp" }}>
            SIP Interconnect Credentials
          </Text>

          <Text
            variant="sm"
            css={{ color: "$textDisabled", mt: "$4", mb: "$12" }}
          >
            SIP credentials are required to use 100ms' SIP Interconnect which is
            currently in preview access.
            <a
              className="text-primary-light hover:text-primary-default"
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/Session Initiation Protocol (SIP)/SIP-Interconnect`}
            >
              &nbsp;Learn more.
            </a>
          </Text>

          <Box css={{ mb: "$14" }}>
            <Flex css={{ gap: "$12", marginBottom: "$12" }}>
              <Credential
                readOnly
                {...CredentialsCSS}
                outlined
                label="Username"
                value={current?.Auth?.ID}
                getIsSecret={getIsSecret}
              />
              <Credential
                {...CredentialsCSS}
                outlined
                readOnly
                label="Password"
                value={current?.Auth?.SipSecret}
                getIsSecret={getIsSecret}
              />
            </Flex>
            <Flex css={{ gap: "$12", marginBottom: "$12" }}>
              <Credential
                readOnly
                outlined
                {...CredentialsCSS}
                getIsSecret={getIsSecret}
                label="SIP Endpoint"
                value={
                  process.env.REACT_APP_ENV === "prod"
                    ? "sip.100ms.live"
                    : "qa-sip.100ms.live"
                }
                showClipboard={!!current?.subdomain}
              />
            </Flex>
          </Box>

          <hr />

          <Text variant="h6" css={{ m: "0", mt: "$14", color: "$textHighEmp" }}>
            Configure Webhook
          </Text>
          <Text variant="sm" css={{ color: "$textDisabled", mt: "$4" }}>
            Use a&nbsp;
            <a
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how--to-guides/configure-webhooks/overview`}
              className="text-primary-light hover:text-primary-default"
            >
              webhook URL
            </a>
            &nbsp;to receive the events of this workspace.
          </Text>
          <Box css={{ my: "$xl" }}>
            <Flex align="center">
              <Switch
                checked={enableWebhook}
                onCheckedChange={() => {
                  if (enableWebhook === true) {
                    dispatch(
                      updateWebhookDetails({
                        Webhook: {
                          URL: webhookURL,
                          Headers: getTableData(tableData),
                          Active: false,
                        },
                      })
                    );
                  }
                  setEnableWebhook(prev => !prev);
                }}
              />
              <Text
                variant="xs"
                css={{
                  color: "$textDisabled",
                  marginLeft: "$md",
                }}
              >
                {enableWebhook ? "Enabled" : "Disabled"}
              </Text>
            </Flex>

            {enableWebhook ? (
              <>
                <Box css={{ mt: "$8", w: "100%", mb: "$12" }}>
                  <Flex align="end" css={{ gap: "$3" }}>
                    <Credential
                      readOnly={false}
                      outlined
                      {...CredentialsCSS}
                      showClipboard={false}
                      getIsSecret={getIsSecret}
                      label="Webhook URL"
                      onChangeHandler={val => {
                        const newValue = val.trim();
                        setWebhookURL(newValue);
                      }}
                      value={webhookURL}
                      title=""
                      placeholder="https://my-api.com/api"
                      disabled={isAdmin()}
                    />
                    <Button
                      disabled={
                        invalidWebhookConfig === INVALID_WEBHOOK_URL_MESSAGE ||
                        invalidWebhookConfig ===
                          INVALID_WEBHOOK_URL_MESSAGE_HIPAA
                      }
                      variant="standard"
                      css={{ r: "$0" }}
                      onClick={() => {
                        dispatch(
                          verifyWebhookURLData({
                            url: webhookURL,
                            headers: getTableData(tableData),
                          })
                        );
                      }}
                      loading={verifyWebhookURL === API_CALL_STATE.IN_PROGRESS}
                    >
                      Test Webhook
                    </Button>
                  </Flex>
                  {verifyWebhookURL === API_CALL_STATE.FAILED &&
                  invalidWebhookConfig !== INVALID_WEBHOOK_URL_MESSAGE &&
                  invalidWebhookConfig !== INVALID_WEBHOOK_URL_MESSAGE_HIPAA ? (
                    <Flex
                      align="center"
                      css={{ color: "$error", mt: "$4", gap: "$3" }}
                    >
                      <AlertTriangleIcon height={16} width={16} />
                      <Text
                        variant="xs"
                        css={{
                          c: "$error",
                          w: "fit-content",
                        }}
                      >
                        {verifyWebhookURLError.verifyWebhookURL}
                      </Text>
                    </Flex>
                  ) : null}
                  {invalidWebhookConfig === INVALID_WEBHOOK_URL_MESSAGE ||
                  invalidWebhookConfig === INVALID_WEBHOOK_URL_MESSAGE_HIPAA ? (
                    <Flex
                      align="center"
                      css={{ color: "$error", mt: "$4", gap: "$3" }}
                    >
                      <AlertTriangleIcon height={16} width={16} />
                      <Text
                        variant="xs"
                        css={{
                          c: "$error",
                          maxWidth: "400px",
                        }}
                      >
                        {invalidWebhookConfig}
                      </Text>
                    </Flex>
                  ) : null}
                </Box>

                <Box css={{ w: "100%", mb: "$8" }}>
                  <CustomTable data={tableData} changeData={changeTableData} />
                </Box>

                <Flex css={{ mt: "$3", gap: "$md" }}>
                  <Button
                    css={{ fontWeight: "$semiBold", borderRadius: "$0" }}
                    color="primary"
                    className="w-fit"
                    loading={updateWorkspaceInfo === API_CALL_STATE.IN_PROGRESS}
                    disabled={invalidWebhookConfig}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    css={{ fontWeight: "$semiBold", borderRadius: "$0" }}
                    outlined
                    variant="standard"
                    disabled={
                      invalidWebhookConfig ||
                      updateWorkspaceInfo === API_CALL_STATE.IN_PROGRESS
                    }
                    onClick={() => {
                      setEnableWebhook(current?.Webhook.Active || false);
                      setWebhookURL(current?.Webhook.URL || "");
                      changeTableData([current?.Webhook.Headers || {}]);
                      const lastSavedTableData = [];
                      Object.keys(current?.Webhook.Headers).forEach(key =>
                        lastSavedTableData.push({
                          headerKey: key,
                          headerValue: current.Webhook.Headers[key],
                        })
                      );
                      changeTableData(lastSavedTableData);
                    }}
                  >
                    Reset Changes
                  </Button>
                </Flex>
              </>
            ) : null}

            <hr style={{ marginTop: "2.5rem" }} />
            <Text
              variant="h6"
              css={{ m: "0", mt: "$14", color: "$textHighEmp" }}
            >
              Security
            </Text>
            <Text
              variant="sm"
              css={{ color: "$textDisabled", mt: "$4", mb: "$12" }}
            >
              These settings enable you to manage the security parameters of a
              room, determining the conditions under which a peer can join.
            </Text>
            <Flex css={{ gap: "$72" }}>
              <SwitchWithTitle
                title="Enable room codes"
                checkedValue={!(current?.room_code_disabled || false)}
                onCheckedChange={value => {
                  dispatch(
                    updateWorkspaceDetails({
                      ...current,
                      room_code_disabled: !value,
                    })
                  );
                  dispatch(fetchWorkspaces());
                }}
                tooltipMessage={
                  <div className="w-56">
                    By activating this setting, peers can join a room using a{" "}
                    <a
                      className="text-primary-light"
                      href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/api-reference/room-codes/room-code-overview`}
                    >
                      room code
                    </a>
                    . This is a workspace-level setting.
                  </div>
                }
              />
            </Flex>
          </Box>
        </Container>
      </div>
    </>
  );
}

export default Developer;
